import { MetaSwiper, countUpArr } from "./meta-settings.js";

$(document).ready(function () {
    AOS.init({
        once: true,
    });

    var myHash = location.hash;
    if(myHash[1] != undefined){
        location.hash = '';
        $('html, body').animate({scrollTop: $(myHash).offset().top - 100}, 600);
    }

    $('a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 100
                    }, 600, function() {
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) {
                            return false;
                        } else {
                            $target.attr('tabindex','-1');
                            $target.focus();
                        };
                    });
                }
            }
        });

    var lazyLoadInstance = new LazyLoad();
    lazyLoadInstance.update();

    // custom input
    $(function () {
        $.each($(".js-input-group"), function () {
            var input = new inputHandler($(this));
        });
    });

    function inputHandler($container) {
        this._$inputContainer = $container;
        this._$input = this._$inputContainer.find(".js-input");
        this._handleFocus();
        this._handleBlur();
        this._valueHandler();
    }
    inputHandler.prototype._handleFocus = function () {
        var that = this;
        this._$input.on("focus", function () {
            that._$inputContainer.addClass("focus");
        });
    };
    inputHandler.prototype._handleBlur = function () {
        var that = this;
        this._$input.on("blur", function () {
            if (!that._inputHasValue) {
                that._$inputContainer.removeClass("focus");
            }
        });
    };
    inputHandler.prototype._valueHandler = function () {
        var that = this;
        this._$input.on("keyup", function () {
            if (that._$input.val()) {
                that._inputHasValue = true;
                that._$inputContainer.addClass("has-content");
            } else {
                that._inputHasValue = false;
                that._$inputContainer.removeClass("has-content");
            }
        });
    };
    // end custom input

    // fixed btns
    function openByScroll(selector, triggerSelector) {
        const trigger = document.querySelector(triggerSelector);

        if (trigger.offsetTop <= window.pageYOffset) {
            document.querySelector(selector).classList.add("active");
        }

        window.addEventListener("scroll", () => {
            if (trigger.offsetTop <= window.pageYOffset) {
                document.querySelector(selector).classList.add("active");
            } else {
                document.querySelector(selector).classList.remove("active");
            }
        });
    }

    if (window.innerWidth < 1024) {
        if (document.querySelector(".search-form")) {
            openByScroll(".header__btn-wrap", ".search-form");
        } else {
            document.querySelector(".header__btn-wrap").classList.add("active");
        }
    }
    // end fixed btns

    // countUp
    const scrollEvents = () => {
        const trueScrollTarget =
            document.getElementsByClassName("counter__item")[0];

        if (trueScrollTarget) {
            const scrollTarget = document.querySelectorAll(".counter__item");
            let progressArr = [];

            if (document.getElementsByClassName("counter")[0]) {
                progressArr = document.querySelectorAll(
                    ".counter .counter__item"
                );
            }

            const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry, i) => {
                        if (entry.isIntersecting) {
                            if (progressArr.length !== 0 && entry.target.classList.contains("counter__item")) {
                                const elem = entry.target;
                                countUpArr.forEach((countUp) => {
                                    if (countUp.el.id === elem.querySelector(".count-up").id) {
                                        countUp.start();
                                        const progressElem = elem.querySelector(".counter__progress");
                                        const progress = Number(progressElem.dataset.progress);
                                        progressElem.classList.add("animation");
                                        progressElem.style.setProperty("--widthBar", `${Math.min(progress, 100)}%`);
                                    }
                                });
                            }
                        }
                    });
                },
                {
                    threshold: 0,
                    rootMargin: "0% 0% -10% 0%",
                }
            );

            scrollTarget.forEach((target, index) => {
                observer.observe(target);
            });
        }
    };

    scrollEvents();

    // end countUp

    // plyr video player
    const players = Array.from(document.querySelectorAll(".plyr-video-player"));

    players.forEach((video) => {
        const player = new Plyr(video, {
            controls: [
                "play-large",
                "play",
                "progress",
                "current-time",
                "mute",
                "volume",
                "captions",
                "pip",
                "fullscreen",
            ],
        });
    });

    // end plyr video player

    $(".benefits__btn").on("click", function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.toggleClass("active");
        $this.prev().slideToggle();
    });

    $(".vacancies__head.open").toggleClass("active").next().slideToggle();

    $(".vacancies__head").on("click", function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.toggleClass("active");
        $this.next().slideToggle();
    });

    $(".accordion__head.open").toggleClass("active").next().slideToggle();

    $(".accordion__head").on("click", function (e) {
        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass("active")) {
            $(".accordion__content").slideUp(400);
            $(".accordion__head").removeClass("active");
        }

        $this.toggleClass("active");
        $this.next().slideToggle();
    });

    $(function () {
        $(".btn")
            .on("mouseenter", function (e) {
                var parentOffset = $(this).offset(),
                    relX = e.pageX - parentOffset.left - 25,
                    relY = e.pageY - parentOffset.top;
                document.documentElement.style.setProperty(
                    "--btnBeforeTop",
                    `${relY}px`
                );
                document.documentElement.style.setProperty(
                    "--btnBeforeLeft",
                    `${relX}px`
                );
            })
            .on("mouseout", function (e) {
                var parentOffset = $(this).offset(),
                    relX = e.pageX - parentOffset.left - 25,
                    relY = e.pageY - parentOffset.top;
                document.documentElement.style.setProperty(
                    "--btnBeforeTop",
                    `${relY}px`
                );
                document.documentElement.style.setProperty(
                    "--btnBeforeLeft",
                    `${relX}px`
                );
            });
    });

    const paralaxContainer = document.querySelectorAll(".paralax");

    paralaxContainer.forEach((elem) => {
        let parallaxInstance = new Parallax(elem);
        parallaxInstance.enable();
    });

    // nice-select

    window.niceSelectUpd = function niceSelectUpd() {
        $(".search-form__select").niceSelect();
        $(".tabs-box__select").niceSelect();
        $(".subscribe-form__select").niceSelect();
        $(".vacancies__select").niceSelect();

        const selectWrap = document.querySelectorAll(".nice-select .list");

        if (!!selectWrap.length) {
            selectWrap.forEach(
                (select) => new SimpleBar(select, { autoHide: true })
            );
        }
    }
    niceSelectUpd()
    // end nice-select

    // burger-menu
    const burgerMenu = (menuSelector, burgerSelector) => {
        const menuElem = document.querySelector(menuSelector),
            layoutElem = document.querySelector(".header__layout"),
            burgerElem = document.querySelector(burgerSelector);

        const removeClass = () => {
            menuElem.classList.remove("open");
            document.body.classList.remove("no-scroll");
        };
        removeClass();

        menuElem.querySelectorAll("a").forEach((i) => {
            i.addEventListener("click", (e) => {
                if (menuElem.classList.contains("open")) {
                    removeClass();
                }
            });
        });

        burgerElem.addEventListener("click", () => {
            if (menuElem.classList.contains("open")) {
                removeClass();
            } else {
                menuElem.classList.add("open");
                document.body.classList.add("no-scroll");
            }
        });

        layoutElem.addEventListener("click", () => {
            removeClass();
        });

        window.addEventListener("resize", function () {
            if (window.innerWidth > 1200) {
                removeClass();
            }
        });
    };

    burgerMenu(".header", ".header__burger");
    // end burger-menu

    // sticky
    function stickyMenu() {
        const headerElem = document.querySelector(".header");
        if (window.pageYOffset >= 50) {
            headerElem.classList.add("sticky");
        } else {
            headerElem.classList.remove("sticky");
        }
    }

    window.addEventListener("scroll", stickyMenu);
    stickyMenu();
    // end sticky

    // swiper
    const subscribeForm = document.getElementsByClassName(`subscribe-form__slider`)[0];
    if (subscribeForm) {
        const submitButton = document.querySelector('.subscribe-form__btn-next');
        let flag = false;

        const subSlider = new Swiper('.subscribe-form__slider', {
            loop: false,
            slidesPerView: 1,
            allowTouchMove: false,
            effect: "fade",
            navigation: {
                nextEl: ".subscribe-form__btn-next",
                prevEl: ".subscribe-form__btn-prev",
            },
            on: {
                slidePrevTransitionStart: function (swiper) {
                    flag = false;
                },
            }
        });

        submitButton.addEventListener("click", function () {
            if (flag && subSlider.realIndex === subSlider.slides.length - 1) {
                reCaptchaSubscribe()
            }

            if (subSlider.realIndex === subSlider.slides.length - 1) {
                flag = true;
            }
        });
    }

    if (document.querySelector(".team__slider")) {
        MetaSwiper(`.team__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 36,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                prevEl: ".team__btn-prev",
                nextEl: ".team__btn-next",
            },
            scrollbar: {
                el: ".team__scrollbar",
                draggable: true,
            },
            breakpoints: {
                568: {
                    slidesPerView: "auto",
                    spaceBetween: 20,
                },
            },
        });
    }

    if (document.querySelector(".jobs__slider")) {
        MetaSwiper(`.jobs__slider`, {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 32,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                prevEl: ".jobs__btn-prev",
                nextEl: ".jobs__btn-next",
            },
            scrollbar: {
                el: ".jobs__scrollbar",
                draggable: true,
            },
            breakpoints: {
                2560: {
                    slidesPerView: "auto",
                    spaceBetween: 60,
                },
            },
        });
    }
    if (document.querySelector(".process__slider")) {
        MetaSwiper(`.process__slider`, {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 32,
            speed: 500,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                prevEl: ".process__btn-prev",
                nextEl: ".process__btn-next",
            },
            scrollbar: {
                el: ".process__scrollbar",
                draggable: true,
            },
            breakpoints: {
                2560: {
                    slidesPerView: "auto",
                    spaceBetween: 60,
                },
            },
        });
    }
    if (document.querySelector(".events__slider")) {
        MetaSwiper(`.events__slider`, {
            loop: false,
            effect: "creative",
            creativeEffect: {
                limitProgress: 4,
                prev: {
                    scale: 0.566,
                    opacity: 0.6,
                    translate: ["-82%", 0, 0],
                },
                next: {
                    scale: 0.566,
                    opacity: 0.6,
                    translate: ["82%", 0, 0],
                },
            },
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                prevEl: ".events__btn-prev",
                nextEl: ".events__btn-next",
            },
        });
    }
    if (document.querySelector(".meet__slider")) {
        MetaSwiper(`.meet__slider`, {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 31,
            navigation: {
                prevEl: ".meet__btn-prev",
                nextEl: ".meet__btn-next",
            },
            scrollbar: {
                el: ".meet__scrollbar",
                draggable: true,
            },
            breakpoints: {
                2560: {
                    slidesPerView: "auto",
                    spaceBetween: 60,
                },
            },
        });
    }
    if (document.querySelector(".feedback__slider")) {
        MetaSwiper(`.feedback__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
        });
    }

    if (document.querySelector(".about-us__slider")) {
        MetaSwiper(`.about-us__slider`, {
            loop: false,
            slidesPerView: 1,
            // initialSlide: 1,
            spaceBetween: 20,
            centeredSlides: true,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            scrollbar: {
                el: ".about-us__scrollbar",
                draggable: true,
            },
            breakpoints: {
                569: {
                    spaceBetween: 20,
                    slidesPerView: "auto",
                },
            },
        });
    }

    if (document.querySelector(".news__slider")) {
        MetaSwiper(`.news__slider`, {
            loop: false,
            grabCursor: true,
            slidesPerView: "auto",
            spaceBetween: 20,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
        });
    }

    if (document.querySelector(".clients__slider") && innerWidth < 570) {
        MetaSwiper(`.clients__slider`, {
            loop: false,
            grabCursor: true,
            slidesPerView: 2,
            spaceBetween: 0,
            grid: {
                rows: 2,
            },
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
        });
    }
    // end swiper

    // specialism tabs
    function specTabs(selectors) {
        const specElems = document.querySelectorAll(selectors);
        if (!!specElems.length) {
            specElems.forEach((item, i) => {
                item.addEventListener("click", () => {
                    if (!item.classList.contains("active")) {
                        specElems.forEach((item) => {
                            item.classList.remove("active");
                        });
                        item.classList.add("active");
                    }
                });
            });
        }
    }
    specTabs(".specialisms__item");
    specTabs(".services__item");

    // end specialism tabs

    function nameCut() {
        const upload = document.querySelector(".register__upload");
        const uploadLabel = document.querySelector(".register__label");

        if (upload && uploadLabel) {
            upload.addEventListener("change", function () {
                let dots;
                const arr = this.files[0].name.split(".");
                arr[0].length > 6 ? (dots = "...") : (dots = ".");
                const name = arr[0].substring(0, 10) + dots + arr[1];
                uploadLabel.innerHTML = name;
            });
        }
    }

    nameCut();

    function simpleGapFix(el) {
        const wrapper = document.querySelector(el);
        const bar = wrapper.querySelector('.simplebar-vertical');

        if (bar.style.visibility === 'visible') {
            wrapper.style.paddingLeft = '26px';
            wrapper.style.marginLeft = '-2px';
        }
    }

    if (document.querySelector('.info__simple-box')) {
        simpleGapFix('.info__simple-box')
    }
    if (document.querySelector('.employer__simple-box')) {
        simpleGapFix('.employer__simple-box')
    }
});
